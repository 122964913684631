import {Configuration} from '../types/Configuration';

const data: Configuration = {
    settings: {
        "locale": "pt-BR",
        "cycleInSeconds": 3600,
        "browserColor": "#f6828c",
        "lightColor": "#371e30"
    },
    profile: {
        title: "rosanacabral.com",
        name: "Rosana Cabral",
        description: "",
        url: "https://rosanacabral.com/",
        sections: []
    }
};

export default data;
